define("iris/routes/crm/contracts/new", ["exports", "iris/mixins/protected-route"], function (_exports, _protectedRoute) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend(_protectedRoute.default, {
    model: function (params) {
      let store = this.store;
      return Ember.RSVP.hash({
        contract: store.createRecord('contract'),
        partners: store.query('partner', {
          filter: {
            where: {
              or: [{
                customer: 1
              }, {
                subcontractor: 1
              }]
            },
            order: 'name'
          }
        }),
        contracts: store.findAll('contract'),
        users: store.query('account', {
          filter: {
            where: {
              enabled: 1
            }
          }
        }),
        currencies: store.findAll('currency')
      });
    }
  });

  _exports.default = _default;
});