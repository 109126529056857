define("iris/routes/cmdb/entity-types/entities/new-entity", ["exports", "iris/mixins/protected-route"], function (_exports, _protectedRoute) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend(_protectedRoute.default, {
    entityForm: Ember.inject.service(),
    model: function (params) {
      let store = this.store;
      let entityTypeId = this.paramsFor('cmdb.entity-types.entities').entity_type_id;
      return Ember.RSVP.hash({
        columns: store.query('entityAttribute', {
          filter: {
            where: {
              entityTypeId
            },
            include: 'related',
            order: 'orderIndex'
          }
        }),
        entity: store.createRecord('entity'),
        entityType: store.find('entityType', entityTypeId),
        siteZones: store.query('siteZone', {
          filter: {
            include: 'partner',
            order: 'name'
          }
        }),
        entityTypes: store.query('entityType', {
          filter: {
            where: {
              and: [{
                codeStore: 0
              }, {
                template: 0
              }]
            },
            order: 'longName'
          }
        })
      });
    },
    setupController: function (controller, model) {
      this._super(controller, model);

      this.entityForm.setModel(model.entity);
      const parentController = this.controllerFor('cmdb.entity-types.index');
      parentController.set('spinner', false);
    },
    afterModel: function (model) {
      let store = this.store;
      let entityType = model.entityType;
      model.entity.set('entityType', entityType);
      model.entity.set('generateName', entityType.get('generateName'));
      model.entity.get('entityValues').then(values => {
        model.columns.forEach(col => {
          let notFind = true;
          values.forEach(val => {
            if (val.get('entityAttribute.id') === col.id) {
              notFind = false;
            }
          });

          if (notFind) {
            let record = store.createRecord('entityValue', {
              disableDefault: true,
              value: col.get('defaultValue'),
              entity: model.entity,
              entityAttribute: col,
              orderIndex: col.orderIndex
            });
            values.pushObject(record);
          }
        });
      });
    },
    _afterSaveRelation: function (savedEntity) {
      savedEntity.get('entityValues').then(entityValues => {
        entityValues.invoke('save', true);
      });
      savedEntity.get('masterRelations').then(masterRelations => {
        masterRelations.forEach(masterRelation => {
          masterRelation.get('slaveEntity').then(slaveEntity => {
            if (slaveEntity.get('hasDirtyRelations')) {
              slaveEntity.save().then(savedSoftware => {
                masterRelation.save(true);

                this._afterSaveRelation(savedSoftware);
              });
            }
          });
        });
      });
    }
  });

  _exports.default = _default;
});