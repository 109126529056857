define("iris/routes/settings/operations/ticket-audit-list/edit/edit-item", ["exports", "iris/mixins/protected-route"], function (_exports, _protectedRoute) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend(_protectedRoute.default, {
    model(params) {
      let store = this.store;
      return Ember.RSVP.hash({
        auditItem: store.find('ticketAuditListItem', params.id)
      });
    }

  });

  _exports.default = _default;
});