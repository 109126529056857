define("iris/components/kanban/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "tn88alsP",
    "block": "{\"symbols\":[\"column\",\"card\",\"sticky\",\"column\",\"&default\",\"@searchProperties\",\"@sortProperties\",\"@saveAction\",\"@disabled\",\"@columns\"],\"statements\":[[14,5],[0,\"\\n\"],[7,\"div\",true],[10,\"class\",\"flex flex-nowrap overflow-x-scroll pb-5 mt-5 text-gray-700 kanban-container-width\"],[8],[0,\"\\n\"],[4,\"each\",[[23,10,[]]],null,{\"statements\":[[0,\"    \"],[7,\"div\",true],[10,\"class\",\"lg:w-2/12 md:w-2/6\\n        flex-shrink-0 rounded border-2 px-4 pb-4 mx-2 \\n        dark:border-portoDark-gray-6d7a83 \\n        overflow-y-scroll no-scrollbar\"],[10,\"style\",\"height: 70vh;\"],[11,\"id\",[29,[\"scroll-back-container-\",[23,1,[\"id\"]]]]],[8],[0,\"\\n      \"],[5,\"kanban/column\",[],[[\"@column\",\"@searchProperties\",\"@sortProperties\",\"@saveAction\",\"@disabled\"],[[23,1,[]],[23,6,[]],[23,7,[]],[23,8,[]],[23,9,[]]]],{\"statements\":[[0,\"\\n\"],[4,\"block-slot\",[\"columnHeader\"],null,{\"statements\":[[4,\"yield-slot\",[\"columnHeader\",[28,\"block-params\",[[23,4,[]]],null]],null,{\"statements\":[[0,\"            \"],[14,5],[0,\"\\n\"]],\"parameters\":[]},null]],\"parameters\":[4]},null],[4,\"block-slot\",[\"stickyCard\"],null,{\"statements\":[[4,\"yield-slot\",[\"stickyCard\",[28,\"block-params\",[[23,3,[]]],null]],null,{\"statements\":[[0,\"            \"],[14,5],[0,\"\\n\"]],\"parameters\":[]},null]],\"parameters\":[3]},null],[4,\"block-slot\",[\"moveableCard\"],null,{\"statements\":[[4,\"yield-slot\",[\"moveableCard\",[28,\"block-params\",[[23,2,[]]],null]],null,{\"statements\":[[0,\"            \"],[14,5],[0,\"\\n\"]],\"parameters\":[]},null]],\"parameters\":[2]},null],[0,\"      \"]],\"parameters\":[]}],[0,\"\\n    \"],[9],[0,\"\\n\"]],\"parameters\":[1]},null],[9],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "iris/components/kanban/template.hbs"
    }
  });

  _exports.default = _default;
});