define("iris/routes/tasks/delegates/work-logs", ["exports", "iris/routes/tasks/plans/work-logs", "iris/mixins/protected-route"], function (_exports, _workLogs, _protectedRoute) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _workLogs.default.extend(_protectedRoute.default, {
    templateName: 'tasks/plans/work-logs',
    parentRoute: 'tasks.delegates',

    setupController(controller, model) {
      this._super(controller, model);

      controller.set('delegated', true);
    }

  });

  _exports.default = _default;
});