define("iris/routes/settings/finances/tax-number-patterns/edit", ["exports", "iris/mixins/protected-route"], function (_exports, _protectedRoute) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  class TaxNumberPatternEditRoute extends Ember.Route.extend(_protectedRoute.default) {
    async model(params) {
      if (params.id == 'new') {
        const pattern = this.store.createRecord('taxNumberPattern');
        const examples = await pattern.examples;
        const example = this.store.createRecord('taxNumberPatternExample');
        examples.pushObject(example);
        return pattern;
      }

      return this.store.findRecord('taxNumberPattern', params.id).catch(function () {
        return {};
      });
    }

  }

  _exports.default = TaxNumberPatternEditRoute;
});