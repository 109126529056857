define("iris/routes/crm/contracts/edit/grade-costs", ["exports", "iris/mixins/protected-route"], function (_exports, _protectedRoute) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend(_protectedRoute.default, {
    intl: Ember.inject.service(),

    model() {
      let hash = this.modelFor('crm.contracts.edit');
      return hash.contract;
    },

    buttonActions: Ember.computed(function () {
      return [{
        text: this.intl.t('file-user-grade-cost.resetUserGrades'),
        action: 'refreshUserGrades',
        type: 'primary',
        icon: 'fa fa-refresh'
      }];
    }),

    setupController(controller, model) {
      this._super(controller, model);
      /*ACL*/


      let disableInput = this.controllerFor('crm.contracts.edit').get('disableInput');
      this.controller.set('disableInput', disableInput); //this.controller.set('buttonActions', this.get('buttonActions'));
    },

    actions: {
      refreshUserGrades() {
        let store = this.store;
        let contract = this.modelFor(this.routeName);
        contract.get('contractUserGradeCosts').then(contractUserGradeCosts => {
          contractUserGradeCosts.forEach(contractUserGradeCost => {
            contract.get('toDelete.GradeCosts').addObject(contractUserGradeCost);
          });
          contract.set('contractUserGradeCosts', Ember.A());
          store.findAll('userGrade').then(userGrades => {
            userGrades.forEach((userGrade, i) => {
              let cugc = store.createRecord('contractUserGradeCost', {
                cost: userGrade.get('cost'),
                userGrade: userGrade,
                contract: contract
              });
              contract.get('contractUserGradeCosts').pushObject(cugc);
            });
          });
        });
      }

    }
  });

  _exports.default = _default;
});