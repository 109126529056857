define("iris/routes/dashboard/ticket/work-logs", ["exports", "iris/mixins/protected-route"], function (_exports, _protectedRoute) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend(_protectedRoute.default, {
    model() {
      return this.modelFor(this.get('parentRouteName'));
    },

    setupController(controller, model) {
      this._super(controller, model);

      controller.set('parentRoute', this.get('parentRouteName'));
    },

    actions: {
      didTransition() {
        const workLogs = this.controller.get('model').hasMany('workLogs');
        workLogs.reload();
        return true;
      }

    }
  });

  _exports.default = _default;
});