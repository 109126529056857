define("iris/routes/finances/incoming-invoices/invoice-payments", ["exports", "iris/mixins/protected-route"], function (_exports, _protectedRoute) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend(_protectedRoute.default, {
    sessionAccount: Ember.inject.service(),

    setupController(controller, model) {
      let m = Ember.A();
      const store = this.get('store');
      let payValue = 0;
      const account = this.get('sessionAccount.account');
      const now = new Date();
      store.find('paymentMethod', 'SYS_04').then(transfer => {
        model.forEach(invoice => {
          payValue = invoice.get('vat') + invoice.get('netto') - invoice.get('paidBalance');
          m.push(store.createRecord('incomingInvoicePayment', {
            invoice: invoice,
            payValue: payValue,
            user: account,
            paymentMethod: transfer,
            datePayment: now,
            dateEntered: now
          }));
        });
      });

      this._super(controller, m);

      controller.set('paymentMethods', store.findAll('paymentMethod'));
      controller.set('globalDatePayment', now);
    },

    actions: {
      back() {
        this.transitionTo('finances.incoming-invoices');
        let invoicePayments = this.get('store').peekAll('incomingInvoicePayment');
        invoicePayments.forEach(invoicePayment => {
          if (!invoicePayment.id) {
            invoicePayment.destroyRecord();
          }
        });
      },

      savePayments(payments) {
        this.transitionTo('finances.incoming-invoices');
        payments.forEach(async payment => {
          payment.set('datePayment', this.controller.get('globalDatePayment'));
          let saved = await payment.save();
          let invoice = await saved.get('invoice');
          let balance = invoice.get('paidBalance') + saved.get('payValue');
          invoice.set('paidBalance', balance);

          if (invoice.get('paidBalance') >= invoice.get('gross')) {
            invoice.set('datePaid', payment.get('datePayment'));
          } else {
            invoice.set('datePaid', null);
          }

          await invoice.save();
        });
      }

    }
  });

  _exports.default = _default;
});