define("iris/routes/finances/invoices/report", ["exports", "moment", "iris/mixins/protected-route"], function (_exports, _moment, _protectedRoute) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend(_protectedRoute.default, {
    ajax: Ember.inject.service(),
    sessionAccount: Ember.inject.service(),
    flashMessages: Ember.inject.service(),

    async setupController(controller, model) {
      this._super(controller, model);

      controller.set('from', (0, _moment.default)().add(-4, 'w').format('L'));
      controller.set('to', (0, _moment.default)().format('L'));
      controller.set('processing', false);
      const {
        region
      } = this.sessionAccount;
      const ownerPartner = await region.get('ownerPartner');
      controller.set('invoiceOwner', ownerPartner);
    },

    actions: {
      async generateReport() {
        const {
          controller,
          controller: {
            from,
            to,
            invoiceOwner
          }
        } = this;

        try {
          controller.set('processing', true);
          const response = await this.get('ajax').call('POST', 'invoice', '', 'xml', {
            from,
            to,
            invoiceOwnerId: invoiceOwner.id
          });
          const blob = new Blob([response], {
            type: 'text/xml;charset=utf-8'
          });
          saveAs(blob, 'report.xml');
          controller.set('processing', false);
        } catch (e) {
          console.error(e);
        }
      }

    }
  });

  _exports.default = _default;
});