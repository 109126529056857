define("iris/routes/tasks/work-logs/edit", ["exports", "jquery", "iris/mixins/protected-route"], function (_exports, _jquery, _protectedRoute) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend(_protectedRoute.default, {
    intl: Ember.inject.service(),
    isDownloading: false,
    buttonActions: Ember.computed('isDownloading', function () {
      let isDownloading = this.get('isDownloading');
      return [{
        text: this.intl.t('general.download'),
        action: 'onDownloadBtnClick',
        type: 'primary',
        active: isDownloading,
        iconInactive: 'fa fa-download',
        iconActive: 'fa fa-refresh fa-spin fa-fw'
      }];
    }),
    uploadTitle: Ember.computed(function () {
      return this.intl.t('attachments.uploadTitle');
    }),
    setupController: function (controller, model) {
      this._super(controller, model);

      var owner = Ember.getOwner(this);
      var adapter = owner.lookup('adapter:application');
      let accessToken = this.get('session.data.authenticated.id');
      var url = adapter.buildURL();
      model.set('listOfFiles', Ember.A([]));
      let listOfFiles = model.get('listOfFiles');

      _jquery.default.getJSON(url + '/WorkLogContainers/' + model.id + '/files', {
        access_token: accessToken
      }).then(function (data) {
        // model.set('listOfFiles', Ember.A([data]));
        listOfFiles.addObjects(data);
      });

      if (!Ember.isPresent(this.controller.get('uploadTitle')) || !Ember.isPresent(model.attachment)) {
        this.controller.set('uploadTitle', this.get('uploadTitle'));
      }

      this.controller.set('buttonActions', this.get('buttonActions'));
    },
    actions: {
      onDeleteBtnClick: function (selectedRecords) {
        let model = this.modelFor('tasks.work-logs.edit');
        Ember.set(model, 'toDeleteFileAttachments', selectedRecords);
        this.controller.set('deleteConfirmation', true);
      },
      confirmDelete: function () {
        let model = this.modelFor('tasks.work-logs.edit');
        let selectedRecords = Ember.get(model, 'toDeleteFileAttachments');
        let listOfFiles = this.controller.get('model.listOfFiles');
        listOfFiles.removeObjects(selectedRecords);
        this.send('deleteAttachments', selectedRecords);
        this.controller.set('deleteConfirmation', false);
      },
      cancelDelete: function () {
        let model = this.modelFor('tasks.work-logs.edit');
        Ember.set(model, 'toDeleteFileAttachments', null);
        this.controller.set('deleteConfirmation', false);
      },
      setWorkLogAttachment: function (attachment) {
        let model = this.modelFor('tasks.work-logs.edit');
        let sAttachments = Ember.get(model, 'attachment');

        if (typeof sAttachments !== 'undefined' && sAttachments != null) {
          sAttachments.addObject(attachment);
        } else {
          sAttachments = Ember.A([attachment]);
        }

        Ember.set(model, 'attachment', sAttachments);
        var title = this.controller.get('uploadTitle');
        title = '';
        sAttachments.forEach(item => {
          title = title + ' ' + item.file.name;
        });
        this.controller.set('uploadTitle', title); // this.send('uploadAttachments', model);
      },
      onDownloadBtnClick: function (selectedRecords) {
        this.set('isDownloading', true);
        this.controller.set('buttonActions', this.get('buttonActions'));
        this.controller.set('selectedRecords', selectedRecords);

        if (selectedRecords.length > 0) {
          let model = this.modelFor('tasks.work-logs.edit');
          let owner = Ember.getOwner(this);
          let adapter = owner.lookup('adapter:application');
          let accessToken = this.get('session.data.authenticated.id');
          selectedRecords.forEach(record => {
            let url = adapter.buildURL();
            url = url + '/WorkLogContainers/' + model.id + '/download/' + record.name + '?access_token=' + accessToken;

            _jquery.default.ajax(url, {
              cache: false
            }).success(function (data) {
              let blob = new Blob([data], {
                type: 'application/octet-stream'
              });
              saveAs(blob, record.name);
            });

            this.set('isDownloading', false);
            this.controller.set('buttonActions', this.get('buttonActions'));
          });
        }
      }
    }
  });

  _exports.default = _default;
});