define("iris/routes/operation/change-requests/edit", ["exports", "iris/mixins/protected-route"], function (_exports, _protectedRoute) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend(_protectedRoute.default, {
    async model(params) {
      return this.store.find('changeRequest', params.id);
    },

    setupController(controller, model) {
      // Reloading the model fixes the following use-case:
      //
      // 1. Open a CR
      // 2. In another browser tab: change something, then hit save
      // 3. Visit the CR list in the first window
      // 4. Open the CR again
      // 5. The model is dirty (but it shouldn't be)
      model.reload();
      return this._super(controller, model);
    },

    actions: {
      willTransition(transition) {
        const record = this.get('controller.model');

        if (record.get('isDirty')) {
          record.rollback();
        }
      }

    }
  });

  _exports.default = _default;
});