define("iris/routes/settings/finances/vats/edit", ["exports", "iris/utils/vat-types", "iris/mixins/protected-route"], function (_exports, _vatTypes, _protectedRoute) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  class VatEditRoute extends Ember.Route.extend(_protectedRoute.default) {
    model(params) {
      if (params.id == 'new') {
        return this.store.createRecord('vat');
      }

      return this.store.findRecord('vat', params.id).catch(function () {
        return {};
      });
    }

    setupController(controller, model) {
      super.setupController(...arguments);
      controller.set('vatTypes', _vatTypes.vatTypes);
      controller.set('selectedType', _vatTypes.vatTypes.findBy('id', model.get('vatType')));
    }

  }

  _exports.default = VatEditRoute;
});