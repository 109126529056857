define("iris/routes/tasks/tasks", ["exports", "iris/routes/tasks/plans", "iris/mixins/protected-route"], function (_exports, _plans, _protectedRoute) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _plans.default.extend(_protectedRoute.default, {
    templateName: 'tasks/plans'
  });

  _exports.default = _default;
});