define("iris/routes/crm/operational-file/index", ["exports", "iris/routes/operation/operational-file/index", "iris/mixins/protected-route"], function (_exports, _index, _protectedRoute) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _index.default.extend(_protectedRoute.default, {
    templateName: 'crm/operational-file/index'
  });

  _exports.default = _default;
});