define("iris/routes/settings/operations/ticket-audit-list/edit", ["exports", "iris/mixins/protected-route"], function (_exports, _protectedRoute) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend(_protectedRoute.default, {
    model(params) {
      let store = this.store;
      return Ember.RSVP.hash({
        auditList: store.find('ticketAuditList', params.id),
        auditListItems: store.query('ticketAuditListItem', {
          filter: {
            where: {
              listId: params.id
            }
          }
        })
      });
    },

    actions: {
      refresh() {
        this.refresh();
      }

    },
    setupController: function (controller, model) {
      this._super(controller, model);
    }
  });

  _exports.default = _default;
});