define("iris/routes/operation/portal-dashboard/new-incident", ["exports", "iris/mixins/protected-route"], function (_exports, _protectedRoute) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const SOURCE_ID_WEB_TICKET = 'SYS_01';

  var _default = Ember.Route.extend(_protectedRoute.default, {
    sessionAccount: Ember.inject.service(),
    store: Ember.inject.service(),

    async model() {
      let currentUser = await this.sessionAccount.account;
      let contacts = await this.store.query('contact', {
        filter: {
          where: {
            email: currentUser.get('email')
          }
        }
      });
      let ticketSource = await this.store.find('ticketSource', SOURCE_ID_WEB_TICKET);
      return this.store.createRecord('ticket', {
        preTicket: true,
        createUser: currentUser,
        contact: contacts.get('firstObject'),
        ticketSource,
        isOperational: true
      });
    },

    actions: {
      refresh() {
        this.refresh();
      }

    }
  });

  _exports.default = _default;
});