define("iris/routes/finances/pipelines/opportunities", ["exports", "iris/mixins/protected-route"], function (_exports, _protectedRoute) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend(_protectedRoute.default, {
    model: function (params) {
      var store = this.store;
      return Ember.RSVP.hash({
        fileBusinessTypes: store.query('fileBusinessType', {
          filter: {
            order: 'name'
          }
        })
      });
    }
  });

  _exports.default = _default;
});