define("iris/routes/settings/aiops/optimization-item-types/edit", ["exports", "iris/mixins/protected-route"], function (_exports, _protectedRoute) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  class AiopsOptimizationItemTypeEditRoute extends Ember.Route.extend(_protectedRoute.default) {
    model(params) {
      if (params.optimization_item_type_id == 'new') {
        return this.store.createRecord('aiopsOptimizationItemType');
      }

      return this.store.findRecord('aiopsOptimizationItemType', params.optimization_item_type_id).catch(function () {
        return {};
      });
    }

  }

  _exports.default = AiopsOptimizationItemTypeEditRoute;
});