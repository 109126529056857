define("iris/routes/operation/sd-dashboard/edit", ["exports", "iris/mixins/protected-route"], function (_exports, _protectedRoute) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend(_protectedRoute.default, {
    async model(params) {
      const tickets = await this.store.query('ticket', {
        filter: {
          where: {
            id: params.id
          },
          include: ['type', 'state', 'contact', 'resolver', 'solvingGroup', 'comments', 'documents', 'repairForm']
        }
      });

      if (tickets.length === 0) {
        return this.transitionTo('/forbidden');
      } else {
        return tickets.get('firstObject');
      }
    }

  });

  _exports.default = _default;
});