define("iris/routes/tasks/availabilities/edit", ["exports", "iris/mixins/protected-route"], function (_exports, _protectedRoute) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend(_protectedRoute.default, {
    model(params) {
      let store = this.store;
      return Ember.RSVP.hash({
        availability: store.find('availability', params.availability_id),
        files: store.query('project', {
          filter: {
            where: {
              closed: 0
            },
            order: 'name'
          }
        }),
        businessServices: store.query('businessService', {
          filter: {
            order: 'name'
          }
        }),
        serviceAreas: store.query('serviceArea', {
          filter: {
            order: 'title'
          }
        })
      });
    },

    setupController(controller, model) {
      model.availability.startTrack();

      this._super(controller, model);
    },

    actions: {}
  });

  _exports.default = _default;
});