define("iris/routes/reports/inventories/new", ["exports", "iris/utils/model", "iris/utils/create-record", "iris/mixins/protected-route"], function (_exports, _model, _createRecord, _protectedRoute) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend(_protectedRoute.default, {
    ajax: Ember.inject.service(),
    sessionAccount: Ember.inject.service(),
    model: async function (params) {
      var store = this.store;
      let newInventory = await (0, _createRecord.createInventory)(this.store, this.sessionAccount);
      let currentUser = this.get('session.data.authenticated');
      let footerFilter = {
        filter: {
          order: 'name'
        }
      };

      if (currentUser.builtInRole === '__PortalUsers') {
        let user = await store.findRecord('account', this.get('session.data.authenticated.userId'), {
          include: 'contact'
        });
        let contact = await user.get('contact');
        let partner = await contact.get('partner');
        footerFilter.filter['where'] = {
          partnerId: partner.get('id')
        };
      }

      let footers = await store.query('footer', footerFilter);

      if (footers.length === 1) {
        newInventory.set('footer', footers.get('firstObject'));
      }

      return Ember.RSVP.hash({
        inventory: newInventory,
        footers: footers
      });
    },
    actions: {
      /*
      willTransition: function(transition) {
        var record = this.controller.get('model.partner');
        if (record.get('isNew')) {
          record.deleteRecord();
        }
      },
      */
      async setInventory(inventory) {
        const {
          model,
          validations
        } = await (0, _model.validateRecord)(inventory);

        if (!validations.get('isValid')) {
          model.setValidated();
          return;
        }

        let partnerIds = [];
        let contactIds = [];
        let footerId = inventory.get('footer.id');

        if (inventory.get('chooseContacts')) {
          inventory.get('contacts').forEach(contact => {
            contactIds.push(contact.get('id'));
          });

          if (contactIds.length === 0) {
            const departmentIds = inventory.get('departments').mapBy('id');
            const departmentContacts = await this.store.query('contact', {
              filter: {
                where: {
                  departmentId: {
                    inq: departmentIds
                  }
                }
              }
            });
            departmentContacts.forEach(contact => {
              contactIds.push(contact.get('id'));
            });
          }

          let buildings = Ember.A();
          let floors = Ember.A();
          inventory.get('floors').forEach(floor => {
            floors.pushObject(floor);
          });

          if (inventory.get('addresses.length')) {
            inventory.get('addresses').forEach(address => {
              address.get('buildings').forEach(building => {
                if (inventory.get('buildings.length')) {
                  if (inventory.get('buildings').toArray().contains(building)) {
                    buildings.pushObject(building);
                  }
                } else {
                  buildings.pushObject(building);
                }
              });
            });
          } else if (inventory.get('buildings.length')) {
            inventory.get('buildings').forEach(building => {
              buildings.pushObject(building);
            });
          }

          buildings.forEach(building => {
            building.get('floors').forEach(floor => {
              console.log(inventory.get('floors'), inventory.get('floors.length'));

              if (inventory.get('floors.length')) {
                if (inventory.get('floors').toArray().contains(floor)) {
                  floors.pushObject(floor);
                }
              } else {
                floors.pushObject(floor);
              }
            });
          });

          if (contactIds.length === 0) {
            const floorIds = floors.mapBy('id');
            const locations = await this.store.query('location', {
              filter: {
                where: {
                  floorId: {
                    inq: floorIds
                  }
                }
              }
            });
            const locationIds = locations.mapBy('id');
            const entities = await this.store.query('entity', {
              filter: {
                where: {
                  locationId: {
                    inq: locationIds
                  }
                }
              }
            });
            entities.forEach(entity => {
              contactIds.push(entity.get('contact.id'));
            });
          }

          this.send('genInventory', [], contactIds, footerId, !!inventory.forAssignedContact);
        } else {
          inventory.get('partners').forEach(partner => {
            partnerIds.push(partner.get('id'));
          });
          this.send('genInventory', partnerIds, [], footerId, !!inventory.forAssignedContact);
        }
      },

      genInventory: function (partnerIds, contactIds, footerId) {
        let forAssignedContact = arguments.length > 3 && arguments[3] !== undefined ? arguments[3] : false;
        const flashMessages = Ember.get(this, 'flashMessages');
        const regionId = this.get('sessionAccount.account.regionId');
        this.get('model.inventory');
        this.get('ajax').call('POST', 'inventory', '', 'genInventory', {
          partnerIds: partnerIds,
          contactIds: contactIds,
          regionId: regionId,
          footerId: footerId,
          forAssignedContact
        }).then(res => {
          if (res.result.code) {
            flashMessages.add({
              message: res.result.message,
              title: 'IRIS message',
              icon: 'warning'
            });
          } else {
            flashMessages.add({
              message: res.result.message,
              title: 'IRIS message',
              icon: 'info'
            });

            let _this = this;

            Ember.run.later({}, function () {
              var controller = _this.controllerFor('reports.inventories');

              controller.set('refresh', false);
              Ember.run.next(function () {
                controller.set('refresh', true);
              });
            }, 500);
          }
        });
        this.transitionTo('reports.inventories');
      }
    }
  });

  _exports.default = _default;
});