define("iris/routes/settings/operations/statuses/edit", ["exports", "iris/mixins/protected-route"], function (_exports, _protectedRoute) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  // DEPRECATED, see `settings.operations.ticket-states.edit`
  var _default = Ember.Route.extend(_protectedRoute.default, {
    beforeModel() {
      this.replaceWith('settings.operations.ticket-states.edit', {
        ticket_state_id: params.status_id
      });
    }

  });

  _exports.default = _default;
});