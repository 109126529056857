define("iris/routes/tasks", ["exports", "iris/mixins/protected-route"], function (_exports, _protectedRoute) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend(_protectedRoute.default, {
    setupController: function (controller, model) {
      this._super(controller, model);

      controller.set('sessionAccount', this.get('sessionAccount'));
    },
    actions: {
      deleteWorklog(id) {
        if (typeof id === 'string') {
          this.get('store').find('workLog', id).then(workLog => {
            this.send('delete');
          });
        }
      }

    }
  });

  _exports.default = _default;
});