define("iris/routes/settings/news-feed/new", ["exports", "iris/mixins/protected-route"], function (_exports, _protectedRoute) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend(_protectedRoute.default, {
    model() {
      return this.store.createRecord('news');
    },

    actions: {
      afterSave: function () {
        var controller = this.controllerFor('settings.news-feed');
        controller.set('refresh', false);
        Ember.run.next(function () {
          controller.set('refresh', true);
        });
      }
    }
  });

  _exports.default = _default;
});