define("iris/routes/settings/operations/operation-service-level-type/edit", ["exports", "iris/mixins/protected-route"], function (_exports, _protectedRoute) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  class OperationServiceLevelTypeEditRoute extends Ember.Route.extend(_protectedRoute.default) {
    model(params) {
      if (params.id == 'new') {
        return this.store.createRecord('operationServiceLevelType');
      }

      return this.store.findRecord('operationServiceLevelType', params.id).catch(function () {
        return {};
      });
    }

  }

  _exports.default = OperationServiceLevelTypeEditRoute;
});