define("iris/routes/dashboard/ticket/work-logs/edit", ["exports", "iris/routes/tasks/plans/work-logs/edit", "iris/mixins/protected-route"], function (_exports, _edit, _protectedRoute) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _edit.default.extend(_protectedRoute.default, {
    parentRoute: 'dashboard.ticket.work-logs',
    templateName: 'tasks/plans/work-logs/edit'
  });

  _exports.default = _default;
});