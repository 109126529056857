define("iris/templates/settings/acl/roles", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "jBL9KzA2",
    "block": "{\"symbols\":[\"f\",\"account\",\"index\"],\"statements\":[[5,\"auto/grid\",[],[[\"@modelName\",\"@route\",\"@defaultOrder\",\"@defaultFilters\",\"@noDelete\",\"@externalRowActions\",\"@neededFields\"],[\"competency\",\"settings.acl.roles.edit\",\"created DESC\",[28,\"hash\",null,[[\"builtIn\"],[[28,\"hash\",null,[[\"lte\"],[2]]]]]],true,[23,0,[\"rowActions\"]],[28,\"array\",[\"name\",\"accounts\",\"description\"],null]]],{\"statements\":[[0,\"\\n\"],[4,\"if\",[[28,\"eq\",[[23,1,[\"column\",\"name\"]],\"accounts\"],null]],null,{\"statements\":[[4,\"each\",[[28,\"sort-by\",[\"longName\",[23,1,[\"row\",\"accounts\"]]],null]],null,{\"statements\":[[0,\"          \"],[7,\"div\",true],[10,\"style\",\"float: left;\"],[8],[0,\"\\n\"],[4,\"ember-tooltip\",null,[[\"class\"],[\"mw400\"]],{\"statements\":[[0,\"              \"],[5,\"profil-card\",[],[[\"@account\"],[[23,2,[]]]]],[0,\"\\n\"]],\"parameters\":[]},null],[4,\"if\",[[28,\"not\",[[28,\"is-last-index\",[[23,3,[]],[23,1,[\"row\",\"accounts\",\"length\"]]],null]],null]],null,{\"statements\":[[0,\"              \"],[1,[23,2,[\"longName\"]],false],[0,\", \\n\"]],\"parameters\":[]},{\"statements\":[[0,\"              \"],[1,[23,2,[\"longName\"]],false],[0,\"\\n\"]],\"parameters\":[]}],[0,\"          \"],[9],[0,\"\\n\"]],\"parameters\":[2,3]},null]],\"parameters\":[]},{\"statements\":[[0,\"    \"],[6,[23,1,[\"c\",\"cell\"]],[],[[],[]]],[0,\"\\n\"]],\"parameters\":[]}]],\"parameters\":[1]}],[0,\"\\n\\n\"],[1,[22,\"outlet\"],false],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "iris/templates/settings/acl/roles.hbs"
    }
  });

  _exports.default = _default;
});