define("iris/routes/finances/bank-balances/edit", ["exports", "iris/mixins/protected-route"], function (_exports, _protectedRoute) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  class BankBalanceEditRoute extends Ember.Route.extend(_protectedRoute.default) {
    model(params) {
      if (params.id == 'new') {
        return this.store.createRecord('bankBalance');
      }

      return this.store.findRecord('bankBalance', params.id).catch(function () {
        return {};
      });
    }

  }

  _exports.default = BankBalanceEditRoute;
});