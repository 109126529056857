define("iris/routes/tasks/work-logs/new", ["exports", "moment", "iris/mixins/protected-route"], function (_exports, _moment, _protectedRoute) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend(_protectedRoute.default, {
    sessionAccount: Ember.inject.service(),
    ajax: Ember.inject.service(),
    intl: Ember.inject.service(),

    model() {
      let date = (0, _moment.default)();
      let round = 15;
      let remainder = (round - date.minute()) % round;
      date.add(remainder, 'm');
      let user = this.get('sessionAccount.account');
      return this.store.createRecord('workLog', {
        description: this.intl.t('operation.report-submit.new-worksheet'),
        user: user,
        beginDate: date.toDate(),
        endDate: date.clone().add(1, 'h').toDate()
      });
    },

    buttonActions: Ember.computed(function () {
      return [{
        text: this.intl.t('general.download'),
        action: 'onDownloadBtnClick',
        type: 'primary',
        icon: 'fa fa-download'
      }];
    }),
    uploadTitle: Ember.computed(function () {
      return this.intl.t('attachments.uploadTitle');
    }),
    setupController: function (controller, model) {
      this._super(controller, model);

      if (!Ember.isPresent(this.controller.get('uploadTitle'))) {
        this.controller.set('uploadTitle', this.get('uploadTitle'));
      }

      this.controller.set('buttonActions', this.get('buttonActions'));
      let id = this.get('sessionAccount.account.id');
      this.store.findAll('myTicket').then(result => {
        controller.set('tickets', Ember.A(result));
      });
    },
    actions: {
      setWorkLogAttachment: function (attachment) {
        let model = this.modelFor('tasks.work-logs.new');
        let sAttachments = Ember.get(model, 'attachment');

        if (typeof sAttachments !== 'undefined' && sAttachments != null) {
          sAttachments.addObject(attachment);
        } else {
          sAttachments = Ember.A([attachment]);
        }

        Ember.set(model, 'attachment', sAttachments);
        var title = this.controller.get('uploadTitle');
        title = '';
        sAttachments.forEach(item => {
          title = title + ' ' + item.file.name;
        });
        this.controller.set('uploadTitle', title); // this.send('uploadAttachments', model);
      }
    }
  });

  _exports.default = _default;
});