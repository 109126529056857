define("iris/routes/settings/date-type-definitions", ["exports", "iris/mixins/protected-route"], function (_exports, _protectedRoute) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend(_protectedRoute.default, {
    actions: {
      async afterSave(savedDateTypeDefinition) {
        const dates = await savedDateTypeDefinition.get('dates');
        dates.invoke('save');
      }

    }
  });

  _exports.default = _default;
});