define("iris/routes/reports/inventories/contacts/items", ["exports", "iris/mixins/protected-route"], function (_exports, _protectedRoute) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend(_protectedRoute.default, {
    intl: Ember.inject.service(),
    model: function (params) {
      return this.store.find('inventoryContact', params.inventory_contact_id);
    },
    tabs: Ember.computed(function () {
      return [{
        name: this.intl.t('menus.entities'),
        route: 'reports.inventories.contacts.items.list'
      }, {
        name: this.intl.t('menus.moves'),
        route: 'reports.inventories.contacts.items.moves'
      }];
    }),
    setupController: function (controller, model) {
      this._super(controller, model);

      controller.set('tabs', this.get('tabs'));
    }
  });

  _exports.default = _default;
});