define("iris/routes/settings/operations/ticket-states/index", ["exports", "iris/mixins/protected-route"], function (_exports, _protectedRoute) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend(_protectedRoute.default, {
    model() {
      return this.get('store').query('ticketState', {
        filter: {
          where: {
            and: [{
              sd: 1
            }, {
              or: [{
                isSystemType: false
              }, {
                isSystemType: null
              }]
            }]
          }
        }
      });
    },

    setupController(_controller, model) {
      // Use parent controller to store data - this route has no template, only
      // using the nesting & hook logic for our adventage here.
      this.controllerFor('settings.operations.ticket-states').set('model', model);
    }

  });

  _exports.default = _default;
});