define("iris/routes/cmdb/entity-types/entities/index", ["exports", "iris/mixins/protected-route"], function (_exports, _protectedRoute) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend(_protectedRoute.default, {
    model(params) {
      let entityTypeId = this.paramsFor('cmdb.entity-types.entities').entity_type_id;
      return this.store.find('entityType', entityTypeId);
    }

  });

  _exports.default = _default;
});