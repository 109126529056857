define("iris/routes/organizations/sites/floors/edit", ["exports", "iris/mixins/protected-route"], function (_exports, _protectedRoute) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  class FloorEditRoute extends Ember.Route.extend(_protectedRoute.default) {
    model(params) {
      if (params.floor_id == 'new') {
        return this.store.createRecord('floor');
      }

      return this.store.findRecord('floor', params.floor_id).catch(function () {
        return {};
      });
    }

  }

  _exports.default = FloorEditRoute;
});