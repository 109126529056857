define("iris/routes/settings/user-grades/edit", ["exports", "iris/mixins/protected-route"], function (_exports, _protectedRoute) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend(_protectedRoute.default, {
    model(params) {
      return this.store.find('userGrade', params.user_grade_id);
    },

    actions: {}
  });

  _exports.default = _default;
});