define("iris/routes/settings/operations/operation-service-level-type-manager/edit", ["exports", "iris/mixins/protected-route"], function (_exports, _protectedRoute) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  class OperationServiceLevelTypeManagerEditRoute extends Ember.Route.extend(_protectedRoute.default) {
    model(params) {
      if (params.id == 'new') {
        return this.store.createRecord('operationServiceLevel');
      }

      return this.store.findRecord('operationServiceLevel', params.id).catch(function () {
        return {};
      });
    }

  }

  _exports.default = OperationServiceLevelTypeManagerEditRoute;
});