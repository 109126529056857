define("iris/routes/operation/report-submit/close-report", ["exports", "iris/mixins/protected-route"], function (_exports, _protectedRoute) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend(_protectedRoute.default, {
    model() {
      let store = this.store;
      return Ember.RSVP.hash({
        ticket: store.createRecord('ticket')
      });
    },

    setupController: function (controller, model) {
      this._super(controller, model);

      this.currentModel.ticket = this.controllerFor('operation.report-submit').get("model.ticket");
      this.currentModel.ticket.set('reportCloseMotivation', this.currentModel.ticket.get('description'));
    }
  });

  _exports.default = _default;
});