define("iris/routes/human-resource-management/my-events/edit", ["exports", "iris/mixins/protected-route"], function (_exports, _protectedRoute) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend(_protectedRoute.default, {
    async model(params) {
      let event = await this.store.query('event', {
        filter: {
          where: {
            id: params.id
          },
          include: ['user', 'eventDays']
        }
      });
      return event.firstObject;
    }

  });

  _exports.default = _default;
});