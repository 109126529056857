define("iris/routes/beta/partners", ["exports", "iris/mixins/protected-route"], function (_exports, _protectedRoute) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  class PartnersRoute extends Ember.Route.extend(_protectedRoute.default) {
    model() {}

  }

  _exports.default = PartnersRoute;
});