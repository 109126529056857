define("iris/routes/human-resource-management/my-events/new", ["exports", "moment", "iris/mixins/protected-route"], function (_exports, _moment, _protectedRoute) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend(_protectedRoute.default, {
    queryParams: {
      start: {
        refreshModel: true
      },
      end: {
        refreshModel: true
      }
    },

    model(params) {
      return Ember.RSVP.hash({
        start: params.start == 0 ? (0, _moment.default)().add(1, 'd').toDate() : new Date(params.start),
        end: params.end == 0 ? (0, _moment.default)().add(1, 'd').toDate() : new Date(params.end),
        event: this.store.createRecord('event')
      });
    }

  });

  _exports.default = _default;
});