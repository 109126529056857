define("iris/routes/aiops/dashboards/competencies/edit", ["exports", "iris/mixins/protected-route"], function (_exports, _protectedRoute) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  class AiopsCompetencyDashboardRoute extends Ember.Route.extend(_protectedRoute.default) {
    model(params) {
      return this.store.findRecord('aiopsUserGrade', params.competency_id).catch(function () {
        return {};
      });
    }

  }

  _exports.default = AiopsCompetencyDashboardRoute;
});