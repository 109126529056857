define("iris/routes/operation/report-submit/remote-access-informations", ["exports", "iris/mixins/protected-route"], function (_exports, _protectedRoute) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend(_protectedRoute.default, {
    model() {
      let store = this.store;
      return Ember.RSVP.hash({
        ticket: store.createRecord('ticket')
      });
    },

    actions: {
      saveRemoteAccess() {
        var reportController = this.controllerFor('operation.report-submit').get("model.ticket");
        reportController.set('remoteAccess', this.currentModel.ticket.get("remoteAccess"));
        this.transitionTo('operation.report-submit');
      }

    },
    setupController: function (controller, model) {
      this._super(controller, model);

      var remoteAccess = this.controllerFor('operation.report-submit').get("model.ticket.remoteAccess");

      if (this.currentModel.ticket.get('remoteAccess') === null || this.currentModel.ticket.get('remoteAccess') === undefined) {
        this.currentModel.ticket.set('remoteAccess', remoteAccess);
      }
    }
  });

  _exports.default = _default;
});