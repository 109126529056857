define("iris/routes/settings/cmdb/entity-types", ["exports", "iris/mixins/protected-route"], function (_exports, _protectedRoute) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend(_protectedRoute.default, {
    _saveAttributes: function (entityAttributes) {
      let attributesWithUnsavedDependencies = entityAttributes.filterBy('hasUnsavedDependencies');
      let attributes = entityAttributes.filterBy('hasUnsavedDependencies', false);

      let _this = this;

      attributes.invoke('save', true);
      Ember.run.later(function () {
        _this._saveAttributes(attributesWithUnsavedDependencies);
      }, 500);
    },
    actions: {
      afterSave: function (savedEntityType) {
        //Attributes
        savedEntityType.get('entityAttributes').then(entityAttributes => {
          this._saveAttributes(entityAttributes);
        }); //Relations

        savedEntityType.get('entityRelationTemplates').then(entityRelationTemplates => {
          entityRelationTemplates.invoke('save', true);
        });
        savedEntityType.get('inverseEntityRelationTemplates').then(inverseEntityRelationTemplates => {
          inverseEntityRelationTemplates.invoke('save', true);
        }); //delete removed entityAttributes

        let toDelete = savedEntityType.get('toDelete.entityAttributes');
        toDelete.invoke('destroyRecord', true);
        toDelete.clear(); //delete removed entityTypeRelations

        toDelete = savedEntityType.get('toDelete.entityRelationTemplates');
        toDelete.invoke('destroyRecord', true);
        toDelete.clear();
        var controller = this.controllerFor('settings.cmdb.entity-types');
        controller.set('refresh', false);
        Ember.run.next(function () {
          controller.set('refresh', true);
        });
      }
    }
  });

  _exports.default = _default;
});