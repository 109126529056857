define("iris/routes/aiops/source-data/solvers/user-grade-types/edit", ["exports", "iris/mixins/protected-route"], function (_exports, _protectedRoute) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  class AiopsUserGradeTypeEditRoute extends Ember.Route.extend(_protectedRoute.default) {
    model(params) {
      if (params.user_grade_type_id == 'new') {
        return this.store.createRecord('aiopsUserGradeType');
      }

      return this.store.findRecord('aiopsUserGradeType', params.user_grade_type_id).catch(function () {
        return {};
      });
    }

  }

  _exports.default = AiopsUserGradeTypeEditRoute;
});