define("iris/routes/operation/report-submit/documents", ["exports", "iris/mixins/protected-route"], function (_exports, _protectedRoute) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend(_protectedRoute.default, {
    model() {
      let partnerId = this.controllerFor('operation.report-submit').get("model.ticket.contact.partner.id");
      let store = this.store;
      return Ember.RSVP.hash({
        contracts: store.query('contract', {
          filter: {
            where: {
              "partnerId": partnerId
            }
          }
        })
      });
    }

  });

  _exports.default = _default;
});