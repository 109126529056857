define("iris/routes/settings/operations/fetchmail/edit", ["exports", "iris/mixins/protected-route"], function (_exports, _protectedRoute) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _dec4, _class, _descriptor;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  let FetchmailEditRoute = (_dec = Ember.inject.service, _dec2 = Ember._action, _dec3 = Ember._action, _dec4 = Ember._action, (_class = class FetchmailEditRoute extends Ember.Route.extend(_protectedRoute.default) {
    constructor() {
      super(...arguments);

      _initializerDefineProperty(this, "intl", _descriptor, this);
    }

    model(params) {
      if (params.id == 'new') {
        return this.store.createRecord('Fetchmail', {
          authenticationType: 'basic'
        });
      }

      return this.store.findRecord('Fetchmail', params.id).catch(function () {
        return {};
      });
    }

    setupController(controller, model) {
      super.setupController(controller, model);
      const authenticationTypes = Ember.A([]);

      if (model.belongsTo('fetchmailType').id() === 'SYS_01') {
        authenticationTypes.addObjects([{
          id: 'basic',
          name: this.intl.t('fetchmail.authenticationTypeBasic')
        }]);
      } else {
        authenticationTypes.addObjects([{
          id: 'basic',
          name: this.intl.t('fetchmail.authenticationTypeBasic')
        }, {
          id: 'oauth2',
          name: 'OAuth2'
        }]);
      }

      controller.set('authenticationTypes', authenticationTypes);
    }

    setPort(model) {
      if (model.belongsTo('fetchmailType').id() === 'SYS_01') {
        // POP3
        if (model.isSsl) {
          model.set('port', 995);
        } else {
          model.set('port', 110);
        }
      } else if (model.belongsTo('fetchmailType').id() === 'SYS_02') {
        // IMAP
        if (model.isSsl) {
          model.set('port', 993);
        } else {
          model.set('port', 143);
        }
      }
    }

    changeAuthenticationType(model, value) {
      model.set('authenticationType', value.id);
    }

    changeTpye(model, authenticationTypes, type) {
      model.set('fetchmailType', type);
      this.setPort(model);
      authenticationTypes.clear();

      if (type.id === 'SYS_01') {
        // POP3
        this.send('changeAuthenticationType', model, {
          id: 'basic'
        });
        authenticationTypes.addObjects([{
          id: 'basic',
          name: this.intl.t('fetchmail.authenticationTypeBasic')
        }]);
      } else {
        authenticationTypes.addObjects([{
          id: 'basic',
          name: this.intl.t('fetchmail.authenticationTypeBasic')
        }, {
          id: 'oauth2',
          name: 'OAuth2'
        }]);
      }
    }

    changeSSL(model) {
      this.setPort(model);
    }

  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "intl", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class.prototype, "changeAuthenticationType", [_dec2], Object.getOwnPropertyDescriptor(_class.prototype, "changeAuthenticationType"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "changeTpye", [_dec3], Object.getOwnPropertyDescriptor(_class.prototype, "changeTpye"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "changeSSL", [_dec4], Object.getOwnPropertyDescriptor(_class.prototype, "changeSSL"), _class.prototype)), _class));
  _exports.default = FetchmailEditRoute;
});