define("iris/routes/finances/invoices/copy", ["exports", "iris/routes/finances/invoices/new", "moment", "iris/mixins/protected-route"], function (_exports, _new, _moment, _protectedRoute) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _new.default.extend(_protectedRoute.default, {
    intl: Ember.inject.service(),
    copy: true,
    templateName: 'finances/invoices/new',

    async createInvoice(params) {
      let store = this.store;
      let originalInvoice = await store.findRecord('invoice', params.invoice_id);
      let copy = await originalInvoice.copy(true);
      let partner = await copy.get('partner');
      let datePaymentDue = (0, _moment.default)().add(partner.get('paymentDays'), 'd').toDate();
      let currency = await originalInvoice.get('currency');
      copy.set('currency', currency);
      copy.set('datePaymentDue', datePaymentDue);
      copy.set('dateFulfilled', datePaymentDue);
      copy.set('datePaid', null);
      copy.set('paidBalance', 0);
      copy.set('transactionId', null);
      copy.set('navStatus', null);
      let items = await copy.get('items'); // remove empty item

      let empty = items.find(item => {
        return !item.get('quantity');
      });
      items.removeObject(empty);
      copy.set('accountNumber', null);
      return copy;
    },

    setupController(controller, model) {
      this._super(controller, model);

      controller.set('title', this.intl.t('finances.main.new-invoice'));
    }

  });

  _exports.default = _default;
});