define("iris/routes/tasks/time-off/new", ["exports", "iris/mixins/protected-route"], function (_exports, _protectedRoute) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend(_protectedRoute.default, {
    session: Ember.inject.service(),
    onTimeOffIds: Ember.A(),
    actualUserId: '',

    model() {
      let store = this.store;
      let actualUserId = this.get('session.data.authenticated.userId'); //bejelentkezett user

      this.set('actualUserId', actualUserId);
      return Ember.RSVP.hash({
        newTimeOff: store.createRecord('ticket'),
        onTimeOffTickets: store.query('ticket', {
          filter: {
            where: {
              and: [{
                plannedStartDate: {
                  lte: new Date()
                }
              }, {
                plannedEndDate: {
                  gte: new Date()
                }
              }, {
                ticketTypeId: 'SYS_04'
              }]
            }
          },
          include: 'createUser'
        })
      });
    },

    afterModel: function (model) {
      let onTimeOffIds = this.get('onTimeOffIds');
      onTimeOffIds = Ember.RSVP.all(model.onTimeOffTickets.getEach('createUser.id'));
      this.set('onTimeOffIds', onTimeOffIds);
    },
    actions: {
      saveTimeOff(ticket) {
        let store = this.store;
        const flashMessages = Ember.get(this, 'flashMessages');
        store.find('ticketType', 'SYS_04').then(ticketType => {
          ticket.set('type', ticketType);
          ticket.set('ticketNumber', this.get('sessionAccount.region').get('code'));
        }).then(() => {
          ticket.save().then(() => {
            flashMessages.add({
              message: this.intl.t('hrm.holidayRequestSent'),
              title: 'IRIS message',
              icon: 'info'
            });
          }).catch(err => {
            console.error(err);
            let errorMessages = '';

            if (err.errors) {
              err.errors.forEach(error => {
                errorMessages += error.detail + '.';
              });
            } else {
              errorMessages += err;
            }

            flashMessages.add({
              message: errorMessages + '!!!',
              title: 'IRIS message',
              icon: 'warning'
            });
          });
          this.controllerFor('tasks.time-off').send('refresh');
          this.transitionTo('tasks.time-off');
        });
      }

    },
    setupController: function (controller, model) {
      this._super(controller, model);

      let store = this.store;
      let userFilterArray = Ember.A();
      store.find('status', 'SYS_100').then(status => {
        model.newTimeOff.set('status', status);
      });
      userFilterArray.pushObject({
        id: {
          neq: this.get('actualUserId')
        }
      });
      this.get('onTimeOffIds._result').forEach(function (item) {
        userFilterArray.pushObject({
          id: {
            neq: item.toString()
          }
        });
      });
      let users = store.query('account', {
        filter: {
          where: {
            or: userFilterArray
          }
        }
      });
      this.controller.set('users', users);
    }
  });

  _exports.default = _default;
});