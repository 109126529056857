define("iris/routes/aiops/data/interface/edit", ["exports", "iris/mixins/protected-route"], function (_exports, _protectedRoute) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  class AiopsInterfaceEditRoute extends Ember.Route.extend(_protectedRoute.default) {
    model(params) {
      if (params.interface_id == 'new') {
        return this.store.createRecord('aiopsInterface');
      }

      return this.store.findRecord('aiopsInterface', params.interface_id).catch(function () {
        return {};
      });
    }

  }

  _exports.default = AiopsInterfaceEditRoute;
});