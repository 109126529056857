define("iris/routes/finances/invoices/edit", ["exports", "iris/routes/finances/invoices/new", "iris/mixins/protected-route"], function (_exports, _new, _protectedRoute) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _new.default.extend(_protectedRoute.default, {
    templateName: 'finances/invoices/new',
    mode: 'edit',

    model(params) {
      let store = this.store;
      return Ember.RSVP.hash({
        invoice: store.find('invoice', params.invoice_id),
        partners: store.query('partner', {
          filter: {
            where: {
              or: [{
                customer: 1
              }, {
                subcontractor: 1
              }]
            },
            order: 'name'
          }
        }),
        invoiceTypes: store.findAll('invoiceType'),
        currencies: store.findAll('currency'),
        paymentMethods: store.findAll('paymentMethod'),
        projects: store.findAll('project'),
        vats: store.findAll('vat'),
        uoms: store.findAll('uom'),
        profitCenters: store.findAll('profitCenter'),
        fileBusinessTypes: store.findAll('fileBusinessType'),
        fileTypes: store.query('fileType', {
          filter: {
            where: {
              fileBusinessTypeId: {
                neq: 'null'
              }
            }
          }
        })
      });
    }

  });

  _exports.default = _default;
});