define("iris/routes/human-resource-management/event-manager/account", ["exports", "iris/utils/human-resource-management/event-manager/account", "iris/mixins/protected-route"], function (_exports, _account, _protectedRoute) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend(_protectedRoute.default, {
    store: Ember.inject.service(),

    async model(_ref) {
      let {
        id
      } = _ref;
      return Ember.RSVP.hash({
        account: (0, _account.queryAccount)(this.store, id),
        events: (0, _account.queryEvents)(this.store, id),
        eventTypes: this.store.findAll('eventType')
      });
    },

    deactivate() {
      this.controller.set('model', null);
    },

    actions: {
      loading(transition, _originRoute) {
        let controller = this.controllerFor('human-resource-management/event-manager');
        controller.set('isLoading', true);
        transition.promise.finally(function () {
          controller.set('isLoading', false);
        });
      }

    }
  });

  _exports.default = _default;
});