define("iris/routes/operation/operational-file/edit", ["exports", "iris/mixins/protected-route"], function (_exports, _protectedRoute) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend(_protectedRoute.default, {
    sessionAccount: Ember.inject.service(),
    ajax: Ember.inject.service(),

    async model(params) {
      if (params.file_id == 'new') {
        const fileState = await this.store.findRecord('fileState', 'SYS_08'); // Megrendelt

        const contract = this.store.createRecord('contract', {
          name: 'contract'
        }); // Szerződés

        return Ember.RSVP.hash({
          file: this.store.createRecord('project', {
            probability: 100,
            contract,
            fileState,
            type: 'operational'
          })
        });
      }

      const currentUserId = this.get('session.data.authenticated.userId');
      return Ember.RSVP.hash({
        file: this.store.findRecord('project', params.file_id),
        fileUserAssocs: this.ajax.call('GET', 'fileUserAssoc', '', params.file_id + '/get-actions/')
      });
    },

    afterModel: function (model) {
      if (model.file.id) {
        let sessionAccount = this.get('sessionAccount');
        let actions = this.get('sessionAccount.actions');
        let routes = Ember.A();
        model.fileUserAssocs.filter(a => {
          if (a.system_name) {
            actions.pushObject(a);
          }

          if (a.route) {
            routes.pushObject(a);
          }
        });
        sessionAccount.set('actions', actions);
        sessionAccount.set('routes', routes);
      }
    },
    setupController: function (controller, model) {
      this._super(controller, model);
      /*ACL overview */


      let file = model.file;
      const disableInputObject = {
        common: !this.can('read action', {
          obj: file,
          canActions: ['operation.operational-file.data', 'operation.operational-file.data.write']
        }),
        finance: !this.can('read action', {
          obj: file,
          canActions: ['operation.operational-file.finance', 'operation.operational-file.finance.write']
        }),
        financeRead: !this.can('read action', {
          obj: file,
          canActions: ['operation.operational-file.finance', 'operation.operational-file.finance.write', 'operation.operational-file.finance.read']
        }),
        competency: !this.can('read action', {
          obj: file,
          canActions: ['operation.operational-file.acls']
        }),
        ticket: !this.can('read action', {
          obj: file,
          canActions: ['operation.operational-file.tickets-open', 'operation.operational-file.tickets-open.write']
        })
      };
      this.controller.set('disableInputObject', disableInputObject);
    }
  });

  _exports.default = _default;
});