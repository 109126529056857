define("iris/routes/aiops/source-data/customers/severance-pay-types/edit", ["exports", "iris/mixins/protected-route"], function (_exports, _protectedRoute) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  class AiopsSeverancePayTypeEditRoute extends Ember.Route.extend(_protectedRoute.default) {
    model(params) {
      if (params.severance_pay_type_id == 'new') {
        return this.store.createRecord('aiopsSeverancePayType');
      }

      return this.store.findRecord('aiopsSeverancePayType', params.severance_pay_type_id).catch(function () {
        return {};
      });
    }

  }

  _exports.default = AiopsSeverancePayTypeEditRoute;
});