define("iris/templates/settings/operations/resource-groups", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "rhr0bD2T",
    "block": "{\"symbols\":[\"c\"],\"statements\":[[5,\"auto/grid\",[],[[\"@modelName\",\"@route\",\"@defaultOrder\",\"@neededFields\"],[\"resource-group\",\"settings.operations.resource-groups.edit\",\"createDate DESC\",[28,\"array\",[\"name\",\"authorizer\",\"color\"],null]]],{\"statements\":[[0,\"\\n\"],[4,\"if\",[[28,\"eq\",[[23,1,[\"column\",\"name\"]],\"color\"],null]],null,{\"statements\":[[0,\"    \"],[7,\"div\",true],[10,\"class\",\"h-5 w-5 border-black border float-left mr-2\"],[11,\"style\",[29,[\"background-color: \",[23,1,[\"row\",\"color\"]],\";\"]]],[8],[9],[0,\" \"],[1,[23,1,[\"row\",\"color\"]],false],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"    \"],[6,[23,1,[\"c\",\"cell\"]],[],[[],[]]],[0,\"\\n\"]],\"parameters\":[]}]],\"parameters\":[1]}],[0,\"\\n\\n\"],[1,[22,\"outlet\"],false],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "iris/templates/settings/operations/resource-groups.hbs"
    }
  });

  _exports.default = _default;
});