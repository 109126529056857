define("iris/routes/finances/invoices/advance-invoice", ["exports", "iris/routes/finances/invoices/new", "iris/mixins/protected-route"], function (_exports, _new, _protectedRoute) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _new.default.extend(_protectedRoute.default, {
    templateName: 'finances/invoices/new',
    intl: Ember.inject.service(),

    async setupController(controller, model) {
      this._super(controller, model);

      const {
        invoice
      } = model;
      const advance = await this.get('store').find('invoiceType', 'SYS_05');
      invoice.set('invoiceType', advance);
      const item = invoice.get('items.firstObject');
      item.set('description', this.intl.t('finances.main.advance-invoice'));
      controller.set('title', this.intl.t('finances.main.advance-invoice'));
    }

  });

  _exports.default = _default;
});