define("iris/routes/operation/report-submit/report-as-another-user", ["exports", "iris/mixins/protected-route"], function (_exports, _protectedRoute) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend(_protectedRoute.default, {
    model() {
      let store = this.store;
      return Ember.RSVP.hash({
        users: store.findAll('account'),
        newReporter: store.createRecord('account')
      });
    },

    actions: {
      setNewReporter() {
        this.controllerFor('operation.report-submit').get('model.ticket').set('createdBy', this.currentModel.newReporter);
        this.transitionTo('operation.report-submit');
      }

    }
  });

  _exports.default = _default;
});