define("iris/routes/settings/operations/service-areas/edit", ["exports", "iris/mixins/protected-route"], function (_exports, _protectedRoute) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend(_protectedRoute.default, {
    model(params) {
      let store = this.store;
      return Ember.RSVP.hash({
        serviceArea: store.find('serviceArea', params.id),
        solvingGroups: store.findAll('solvingGroup')
      });
    }

  });

  _exports.default = _default;
});