define("iris/routes/aiops/source-data/tickets/ticket-types/edit", ["exports", "iris/mixins/protected-route"], function (_exports, _protectedRoute) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  class AiopsTicketTypeEditRoute extends Ember.Route.extend(_protectedRoute.default) {
    model(params) {
      if (params.ticket_type_id == 'new') {
        return this.store.createRecord('aiopsTicketType');
      }

      return this.store.findRecord('aiopsTicketType', params.ticket_type_id).catch(function () {
        return {};
      });
    }

  }

  _exports.default = AiopsTicketTypeEditRoute;
});