define("iris/components/hrm/resource-period-manager/component", ["exports", "iris/utils/human-resource-management/resource-period-manager/period", "moment", "iris/lib/human-resource-manager/event", "ember-concurrency", "iris/utils/model"], function (_exports, _period, _moment, _event, _emberConcurrency, _model) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _dec4, _dec5, _dec6, _dec7, _dec8, _dec9, _dec10, _dec11, _dec12, _dec13, _dec14, _dec15, _dec16, _dec17, _class, _descriptor, _descriptor2, _descriptor3, _descriptor4, _descriptor5, _descriptor6, _descriptor7, _descriptor8, _descriptor9, _descriptor10;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  let ResourcePeriodManager = (_dec = Ember.inject.service, _dec2 = Ember.inject.service, _dec3 = Ember.inject.service, _dec4 = Ember.inject.service, _dec5 = Ember.inject.service, _dec6 = Ember.inject.service, _dec7 = Ember.computed.or('isLoading'), _dec8 = Ember.computed.alias('session.data.authenticated.userId'), _dec9 = Ember.computed.alias('sessionAccount.substituteForIds'), _dec10 = Ember.computed('events.[]', 'publicHolidays', function () {
    if (!this.publicHolidays || !this.events || !this.events.length) {
      return Ember.A();
    }

    let slicedEvents = [];

    for (const event of this.events.map(e => e)) {
      let tempDate = (0, _moment.default)(event.startDate);
      let tempEvent = {
        id: event.id,
        title: event.name,
        start: null,
        end: null,
        color: event.get('state.resourceKey') === 'closed' ? _event.EventColors.Black : event.get('resourceGroup.color'),
        allDay: true
      };
      const eventEndDate = (0, _moment.default)(event.endDate).add(1, 'd').format();

      while (tempDate.isSameOrBefore(eventEndDate, 'd')) {
        let isHoliday = this.publicHolidays.find(ph => {
          return (0, _moment.default)(ph.date).isSame(tempDate, 'd');
        });

        if (isHoliday && !isHoliday.isWorkDay || // if it is not workDay
        (!isHoliday || !isHoliday.isWorkDay) && (tempDate.day() === 6 || tempDate.day() === 0) || // if it is not workDay and it is weekend
        tempDate.isSame(eventEndDate)) {
          // if it is the last day of the event
          if (tempEvent.start && !tempEvent.end) {
            tempEvent.end = tempDate.format();
            slicedEvents.push({ ...tempEvent
            });
            tempEvent.start = null;
            tempEvent.end = null;
          }
        } else {
          if (!tempEvent.start) {
            tempEvent.start = tempDate.format();
          }
        }

        tempDate.add(1, 'd');
      }
    }

    return slicedEvents;
  }), _dec11 = (0, _emberConcurrency.task)({
    restartable: true
  }), _dec12 = Ember._action, _dec13 = Ember._action, _dec14 = Ember._action, _dec15 = Ember._action, _dec16 = Ember._action, _dec17 = Ember._action, (_class = class ResourcePeriodManager extends Ember.Component {
    constructor() {
      super(...arguments);

      _initializerDefineProperty(this, "router", _descriptor, this);

      _initializerDefineProperty(this, "store", _descriptor2, this);

      _initializerDefineProperty(this, "themeSwitcher", _descriptor3, this);

      _initializerDefineProperty(this, "intl", _descriptor4, this);

      _initializerDefineProperty(this, "session", _descriptor5, this);

      _initializerDefineProperty(this, "sessionAccount", _descriptor6, this);

      _defineProperty(this, "eventId", null);

      _defineProperty(this, "eventTypes", null);

      _defineProperty(this, "isLoading", null);

      _defineProperty(this, "events", null);

      _defineProperty(this, "selectedEvent", null);

      _defineProperty(this, "selectionEvents", null);

      _initializerDefineProperty(this, "_isLoading", _descriptor7, this);

      _defineProperty(this, "buttonText", {
        today: 'Mai nap',
        day: 'Nap'
      });

      _defineProperty(this, "header", {
        left: 'title',
        right: 'today prev,next'
      });

      _initializerDefineProperty(this, "currentUserId", _descriptor8, this);

      _initializerDefineProperty(this, "substituteForIds", _descriptor9, this);

      _initializerDefineProperty(this, "slicedEvents", _descriptor10, this);
    }

    async init() {
      super.init(...arguments);
      this.set('events', await (0, _period.queryEvents)(this.store, this.currentUserId, this.substituteForIds));
      this.set('selectedEvent', this.preSelectedEvent);
    }

    *saveEventTask() {
      let {
        validations
      } = yield (0, _model.validateRecord)(this.selectedEvent);
      this.selectedEvent.setValidated();

      if (!validations.isValid) {
        return;
      }

      const flashMessages = Ember.get(this, 'flashMessages');

      try {
        this.selectedEvent.save();
        this.flashMessages.info(this.intl.t('general.save.success'), {
          title: this.intl.t('general.save.success-title'),
          icon: 'floppy-o'
        });
      } catch (error) {
        flashMessages.danger(error, {
          title: this.intl.t('general.error'),
          icon: 'times',
          sticky: true
        });
      }

      this.set('events', yield (0, _period.queryEvents)(this.store, this.currentUserId, this.substituteForIds));
    }

    async eventClick(info) {
      let selectedEvent = await this.store.findRecord('resource-period', info.id, {
        include: 'state'
      });
      this.set('selectedEvent', selectedEvent);
    }

    focusRange(range) {
      this.$('.full-calendar').fullCalendar('gotoDate', (0, _moment.default)(range.start));
    }

    async cancelEditing() {
      this.selectedEvent.rollback();
      this.selectedEvent.set('eventDays', Ember.A());
      this.set('selectedEvent', null);
      this.set('events', await (0, _period.queryEvents)(this.store, this.currentUserId, this.substituteForIds));
    }

    clearSelection() {
      this.set('selectionEvents', null);
    }

    async reloadEvents() {
      this.set('events', await (0, _period.queryEvents)(this.store, this.currentUserId, this.substituteForIds));
    }

    async dayRender(date, cell) {
      let publicHolidays = this.get('publicHolidays');
      let cellDay = (0, _moment.default)(date).subtract(1, 'd');

      if ((0, _moment.default)(date).day() === 0 || (0, _moment.default)(date).day() === 6) {
        cell.css('background', _event.EventColors.Blue);
      }

      for (let i = 0; i < publicHolidays.length; i++) {
        let date = publicHolidays.objectAt(i).get('date').toISOString();

        if (cellDay.isSame((0, _moment.default)(date), 'day')) {
          if (!publicHolidays.objectAt(i).get('isWorkDay')) {
            cell.css('background', _event.EventColors.Blue);
            return;
          } else {
            cell.css('background', this.themeSwitcher.dark ? '#21262d' : '#f0f0f0');
          }
        }
      }
    }

  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "router", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "store", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "themeSwitcher", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "intl", [_dec4], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor5 = _applyDecoratedDescriptor(_class.prototype, "session", [_dec5], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor6 = _applyDecoratedDescriptor(_class.prototype, "sessionAccount", [_dec6], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor7 = _applyDecoratedDescriptor(_class.prototype, "_isLoading", [_dec7], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor8 = _applyDecoratedDescriptor(_class.prototype, "currentUserId", [_dec8], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor9 = _applyDecoratedDescriptor(_class.prototype, "substituteForIds", [_dec9], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor10 = _applyDecoratedDescriptor(_class.prototype, "slicedEvents", [_dec10], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class.prototype, "saveEventTask", [_dec11], Object.getOwnPropertyDescriptor(_class.prototype, "saveEventTask"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "eventClick", [_dec12], Object.getOwnPropertyDescriptor(_class.prototype, "eventClick"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "focusRange", [_dec13], Object.getOwnPropertyDescriptor(_class.prototype, "focusRange"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "cancelEditing", [_dec14], Object.getOwnPropertyDescriptor(_class.prototype, "cancelEditing"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "clearSelection", [_dec15], Object.getOwnPropertyDescriptor(_class.prototype, "clearSelection"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "reloadEvents", [_dec16], Object.getOwnPropertyDescriptor(_class.prototype, "reloadEvents"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "dayRender", [_dec17], Object.getOwnPropertyDescriptor(_class.prototype, "dayRender"), _class.prototype)), _class));
  _exports.default = ResourcePeriodManager;
});